import "animate.css";
import * as Colyseus from "colyseus.js";
import styles from 'components/ClientStyles.module.scss';
import DefaultView from "components/DefaultView";
import Loading from "components/Loading";
import nosleep from 'nosleep.js';
import React, { Component } from 'react';
import { Route } from 'react-router';
import Lottie from 'react-lottie';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import ChameleonImage from 'images/Chameleon.png';
import BlueChameleon from 'images/Chameleon Icon Blue.png';
import GreenChameleon from 'images/Chameleon icon Green.png';
import YellowBox from 'images/YellowBox.png';
import getAvatarById from "../constants/avatars";

import VoteAnim from 'animations/Ballot.js';




var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    TopicVote: "topic_vote",
    Answering: "answering",
    PlayerVote: "player_vote",
    DecidingVote: "deciding_vote",
    SecretGuess: "secret_guess",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "chameleon";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            reconnectionToken: "",
            logStreamId: "",

            player: null,
            showDefaultView: true,
            doingTutorial: false,
            gameState: GameStates.Loading,

            showVoteTopics: false,
            votedTopicId: 0,
            votingTopics: [],

            isSpectating: false,
            isChameleon: false,
            secretWord: "",
            currentTopic: {},
            //showPlayerStatus: false,
            showClueSection: false,
            currentClue: "",
            canSubmitClue: false,

            voteAnimPaused: true,
            showPlayerVote: false,
            votePlayers: [],
            currentSelectedPlayerId: "",
            canSubmitPlayerVote: false,
            submittedVote: false,

            showWordGuessSection: false,
            currentWordGuess: "",
            canSubmitWordGuess: false,

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,
            players: [],
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
    doCatchup(state) {
        const player = state.players[this.state.myId];
        console.log("game state : " + state.chameleonData.gameState);
        switch (state.chameleonData.gameState) {
            case GameStates.Tutorial:
                if (!player.votedSkip) {
                    this.setState({ doingTutorial: true, showDefaultView: false, });
                }
                break;
            case GameStates.TopicVote:
                console.log(`player ready : ${player.chameleonData.ready}`);
                console.log(`show vote topics : ${this.state.showVoteTopics}`);
                if (!player.chameleonData.ready) {
                    if (!this.state.showVoteTopics) {
                        this.state.room.send("request_topic_vote");
                    }
                }
                break;
            case GameStates.Answering:
                if (!player.chameleonData.ready && !player.chameleonData.spectating) {
                    if (!this.state.showClueSection /*&& !this.state.showPlayerStatus*/) {
                        this.state.room.send("request_current_topic");
                    }
                }
                break;
            case GameStates.PlayerVote:
                if (!player.chameleonData.ready && !player.chameleonData.spectating) {
                    if (!this.state.showPlayerVote) {
                        this.setState({ showPlayerVote: true, showDefaultView: false, });
                    }
                }
                break;
            case GameStates.DecidingVote:
                if (player.id == state.chameleonData.decidingVoteId) {
                    if (!this.state.showPlayerVote) {
                        this.setState({ showPlayerVote: true, showDefaultView: false, });
                    }
                }
                break;
            case GameStates.SecretGuess:
                if (this.state.isChameleon) {
                    this.state.room.send("request_word_guess");
                }
                break;
            default:
                this.setState({
                    showDefaultView: true,
                    showVoteTopics: false,
                    showClueSection: false,
                    //showPlayerStatus: false,
                    showPlayerVote: false,
                    showWordGuessSection: false,
                });
                break;
        }
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false, showDefaultView: true, });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    UpdateTopicVote(topic) {
        if (topic.Id != this.state.votedTopicId) {
            this.state.room.send("update_topic_vote", { topic, });
        }
    }

    SubmitTopicVote = () => {
        if (this.state.votedTopicId != 0) {
            this.setState({ showVoteTopics: false, showDefaultView: true, })
            this.state.room.send("submit_topic_vote");
        }
    }

    //ShowPlayerStatus(topic = {}) {
    //    const currentClue = this.state.roomState.players[this.state.myId].chameleonData.clueAnswer;
    //    this.setState({ showDefaultView: false, showPlayerStatus: true, currentTopic: topic, currentClue, });
    //}

    doContinue = (topic = {}) => {
        const canSubmitClue = !this.clueInAnswers(this.state.currentClue) && this.state.currentClue.length > 0;
        this.setState({
            /* showPlayerStatus: false,*/
            showDefaultView: false,
            showClueSection: true,
            canSubmitClue,
            currentTopic: topic,
            currentClue: this.state.roomState.players[this.state.myId].chameleonData.clueAnswer
        });
    }

    updateClue = (event) => {
        let currentClue = event.target.value;
        this.setState({ currentClue, });
        this.state.room.send("update_clue", { clue: currentClue, });

        //let canSubmitClue = false;
        //if (currentClue.length > 0) {
        //    if (!this.clueInAnswers(currentClue)) {
        //        canSubmitClue = true;
        //    }
        //}
        //this.setState({ canSubmitClue, });
    }
    clueInAnswers(currentClue) {
        let canSubmitClue = false;
        if (this.state.currentTopic.Answers != null) {
            this.state.currentTopic.Answers.forEach((answer) => {
                if (answer.toLowerCase() == currentClue.toLowerCase()) {
                    canSubmitClue = true;
                }
            });
        }
        return canSubmitClue;
    }

    submitClue = () => {
        if (this.state.canSubmitClue) {
            this.setState({ showClueSection: false, showDefaultView: true, canSubmitClue: false, });
            this.state.room.send("submit_clue", { });
        }
    }

    updatePlayerVote(player) {
        this.state.room.send("update_player_vote", { selectedId: player.id });
        //this.setState({ currentSelectedPlayerId: player.id, canSubmitPlayerVote: true, });
    }

    submitPlayerVote = () => {
        if (this.state.canSubmitPlayerVote && !this.state.submittedVote) {
            this.setState({ voteAnimPaused: false, canSubmitPlayerVote: false, submittedVote: true, });
            this.state.room.send("submit_player_vote", { });
        }
    }
    pauseVote = () => {
        this.setState({ showPlayerVote: false, showDefaultView: true, voteAnimPaused: true, });
    }

    updateWordGuess = (event) => {
        //let canSubmitWordGuess = false;
        //let newWordGuess = event.target.value;
        //if (this.state.currentTopic.Answers.includes(newWordGuess)) canSubmitWordGuess = true;
        //this.setState({ currentWordGuess: newWordGuess, canSubmitWordGuess, });

        let newWordGuess = event.target.value;
        this.state.room.send("update_word_guess", { wordGuess: newWordGuess, });
    }

    submitWordGuess = () => {
        if (this.state.canSubmitWordGuess) {
            this.setState({ showDefaultView: true, showWordGuessSection: false, });
            this.state.room.send("submit_word_guess", { wordGuess: this.state.currentWordGuess, });
        }
    }

    ResetRoundVals() {
        this.setState({
            votingTopics: [],

            currentTopic: {},
            currentClue: "",
            canSubmitClue: false,

            currentSelectedPlayerId: "",
            canSubmitPlayerVote: false,
            submittedVote: false,

            currentWordGuess: "",
            canSubmitWordGuess: false,
        });
    }

    notShowingOtherView() {
        return !this.state.showClueSection && !this.state.showPlayerVote && !this.state.showVoteTopics && !this.state.showWordGuessSection && this.state.voteAnimPaused;
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }
    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        const roomId = this.getQueryStringValue("roomId");
        const sessionId = this.getQueryStringValue("sessionId");
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken, });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[this.state.myId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Chameleon, Reconnection Token: ${room.reconnectionToken}`);

                this.startLocationChecks();

                const isSpectating = player.chameleonData.spectating;
                const votedTopicId = player.chameleonData.votedTopicId;
                const isChameleon = player.chameleonData.isChameleon;
                const secretWord = state.chameleonData.secretWord;
                const currentSelectedPlayerId = player.chameleonData.votePlayerId;
                const canSubmitPlayerVote = currentSelectedPlayerId.length > 0;
                const currentWordGuess = player.chameleonData.wordGuess;
                const canSubmitWordGuess = currentWordGuess.length > 0;
                const currentClue = player.chameleonData.clueAnswer;
                const canSubmitClue = !this.clueInAnswers(this.state.currentClue) && this.state.currentClue.length > 0;
                let votePlayers = [];
                state.players.forEach((value, key) => {
                    votePlayers.push(value);
                });
                this.setState({
                    isPaused: state.isPaused,
                    roomState: state,
                    player,
                    isSpectating,
                    votedTopicId,
                    isChameleon,
                    secretWord,
                    votePlayers,
                    currentSelectedPlayerId,
                    canSubmitPlayerVote,
                    currentWordGuess,
                    canSubmitWordGuess,
                    currentClue,
                    canSubmitClue,
                });
                this.doCatchup(state);

                room.state.chameleonData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                });

            });
            room.onStateChange((state) => {
                console.log("room has new state:", state);
                const isSpectating = state.players[this.state.myId].chameleonData.spectating;
                const votedTopicId = state.players[this.state.myId].chameleonData.votedTopicId;
                const isChameleon = state.players[this.state.myId].chameleonData.isChameleon;
                const secretWord = state.chameleonData.secretWord;
                const currentSelectedPlayerId = state.players[this.state.myId].chameleonData.votePlayerId;
                const canSubmitPlayerVote = currentSelectedPlayerId.length > 0;
                const currentWordGuess = state.players[this.state.myId].chameleonData.wordGuess;
                const canSubmitWordGuess = currentWordGuess.length > 0;
                //const currentClue = state.players[sessionId].chameleonData.clueAnswer;
                const canSubmitClue = !this.clueInAnswers(this.state.currentClue) && this.state.currentClue.length > 0;
                let votePlayers = [];
                state.players.forEach((value, key) => {
                    votePlayers.push(value);
                });
                this.setState({
                    roomState: state,
                    player: state.players[this.state.myId],
                    isSpectating,
                    votedTopicId,
                    isChameleon,
                    secretWord,
                    votePlayers,
                    currentSelectedPlayerId,
                    canSubmitPlayerVote,
                    currentWordGuess,
                    canSubmitWordGuess,
                    //currentClue,
                    canSubmitClue,
                });
                this.doCatchup(state);
            });


            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });


            room.state.players.onAdd((player, key) => {
                this.checkAndAddPlayer(player);

                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });
            });


            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true, showDefaultView: false, });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false, showDefaultView: true, });
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });
            room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            // your game message handlers here
            room.onMessage("begin_vote", (message) => {
                console.log("begin_vote", "received on", room.name, message);
                this.setState({ showDefaultView: false, showVoteTopics: true, votingTopics: message.topics, });
            }); 
            room.onMessage("complete_topic_vote", (message) => {
                console.log("complete_topic_vote", "received on", room.name, message);
                this.setState({ showDefaultView: true, showVoteTopics: false, });
            });
            room.onMessage("start_answering", (message) => {
                console.log("start_answering", "received on", room.name, message);
                if (!this.state.player.chameleonData.spectating) {
                    this.doContinue(message.topic);
                }
            }); 
            room.onMessage("begin_player_vote", (message) => {
                console.log("begin_player_vote", "received on", room.name, message);
                if (!this.state.player.chameleonData.spectating) {
                    this.setState({ showPlayerVote: true, showDefaultView: false, });
                }
            });
            room.onMessage("player_vote_split", (message) => {
                console.log("player_vote_split", "received on", room.name, message);
                //if (message.playerToVote.id == this.state.myId) {
                //    this.setState({ showPlayerVote: true, showDefaultView: false, });
                //}
            }); 
            room.onMessage("start_word_guess", (message) => {
                console.log("start_word_guess", "received on", room.name, message);
                if (this.state.isChameleon) {
                    this.setState({ showWordGuessSection: true, showDefaultView: false, currentTopic: message.topic, });
                }
            }); 
            room.onMessage("award_scores", (message) => {
                console.log("award_scores", "received on", room.name, message);
                this.ResetRoundVals();
            });
            room.onMessage("force_finish", (message) => {
                console.log("force_finish", "received on", room.name, message);
                
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            //room.onMessage("catchup", (message) => {
            //    console.log("catchup", "received on", room.name, message);
            //    if (message.data.id == this.state.myId) {
            //        this.doCatchup(message.gameState, message.data);
            //    }
            //});
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Chameleon, code: ${code} Message: ${JSON.stringify(message)}`);

                //LoggingService.logError(message, code);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Chameleon, Code: ${code}`);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Chameleon.";
            if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Chameleon: ${JSON.stringify(e)}`);

            //LoggingService.logError(message, e);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.state.connectionIssue &&
                                <div className={styles.connectionIssueContainer}>
                                    <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                    <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                </div>
                            }
                            {
                                this.state.showDefaultView && this.notShowingOtherView() &&
                                <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.isSpectating &&
                                <div className={styles.spectatingBox}>
                                    <div className={styles.spectatingText}>SPECTATING ROUND</div>
                                    <div className={styles.subText}>You will join next round...</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                            {
                                this.state.showVoteTopics &&
                                <div className={styles.voteTopicsSection}>
                                    <div className={styles.voteTitle}>PICK A TOPIC</div>
                                    <div className={styles.voteTopicsBox}>
                                        {
                                            this.state.votingTopics.map((x) => {
                                                return <div className={`${styles.voteTopic} ${x.Id == this.state.votedTopicId ? styles.selected : ""}`} onClick={() => this.UpdateTopicVote(x)}>
                                                    <div className={styles.topicText}>{x.Topic}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {/*<div className={`${styles.submitButton} ${this.state.votedTopicId == 0 ? styles.disabled : ""}`} onClick={this.SubmitTopicVote}>SUBMIT</div>*/}
                                </div>
                            }
                            {/*{*/}
                            {/*    this.state.showPlayerStatus &&*/}
                            {/*    <div className={styles.playerStatusSection}>*/}
                            {/*        <div className={styles.playerName}>{this.state.player.name}...</div>*/}
                            {/*        {*/}
                            {/*            this.state.isChameleon ?*/}
                            {/*                <div className={styles.boldText}>YOU ARE THE CHAMELEON</div>*/}
                            {/*                :*/}
                            {/*                <div className={styles.textBox}>*/}
                            {/*                    <div className={styles.subText}>THE SECRET WORD IS:</div>*/}
                            {/*                    <div className={styles.boldText}>{this.state.secretWord}</div>*/}
                            {/*                </div>*/}
                            {/*        }*/}
                            {/*        <div className={styles.submitButton} onClick={this.doContinue}>CONTINUE</div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {
                                this.state.showClueSection &&
                                <div className={styles.clueSection}>
                                    {
                                        this.state.isChameleon ?
                                            <div className={`${styles.secretBox} ${styles.chameleon}`}>
                                                <div className={`${styles.secretWord} ${styles.chameleon} ${styles.smaller}`}>
                                                    YOU ARE THE
                                                    <img src={BlueChameleon} className={styles.cornerChameleon} /></div>
                                                <div className={`${styles.secretWord} ${styles.chameleon}`}>CHAMELEON</div>
                                            </div>
                                            :
                                            <div className={styles.secretBox}>
                                                <div className={styles.secretTitle}>Secret word:</div>
                                                <div className={`${styles.secretWord} ${this.state.secretWord.length > 10 && styles.smaller}`}>{this.state.secretWord}</div>
                                            </div>
                                    }
                                    <div className={styles.title}>Your Clue:</div>
                                    <div className={styles.inputSection}>
                                        <input type="text" maxLength={20} onChange={this.updateClue} placeholder="Write a clue here!" value={this.state.currentClue} className={styles.clueInputBox} />    
                                    </div>
                                    <div className={`${styles.submitButton} ${this.state.canSubmitClue ? "" : styles.disabled}`} onClick={this.submitClue}>SUBMIT</div>

                                    <div className={styles.hintText}>
                                        {
                                            this.state.isChameleon ?
                                                "Try write something which could link to the secret word, good luck!"
                                                :
                                                "Don't make it too obvious... otherwise the Chameleon might guess it!"
                                        }
                                    </div>
                                    <img src={ChameleonImage} className={styles.chameleonImage} />
                                </div>
                            }
                            {
                                (this.state.showPlayerVote || !this.state.voteAnimPaused) &&
                                <div className={styles.playerVoteSection}>
                                    <div className={styles.title}>TIME TO VOTE</div>
                                    <div className={`${styles.title} ${styles.smaller}`}>Who do you think<br />was the Chameleon?</div>
                                    <div className={`${styles.title} ${styles.blue}`}>Check through everyone's clues<br/>on the screen before you vote!</div>
                                    <div className={styles.playerList}>
                                        {
                                            this.state.votePlayers.map((x) => {
                                                if (x.id != this.state.myId && !x.chameleonData.spectating) {
                                                    return <div className={`${styles.playerVoteOption}`} onClick={() => this.updatePlayerVote(x)}>
                                                        <div className={`${styles.mugShot} ${this.state.currentSelectedPlayerId.length > 0 && this.state.currentSelectedPlayerId == x.id && styles.selected}`} style={{ backgroundImage: `url(${getAvatarById(x.avatar).mugShot})` }}>
                                                            <img src={getAvatarById(x.avatar).mugShot} style={{ width: "100%", height: "auto", opacity: "0", }} />
                                                        </div>
                                                        <div className={styles.playerName}>{x.name}</div>
                                                        <div className={styles.playerVote}>{x.chameleonData.clueAnswer}</div>
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                                    <div className={`${styles.voteButton} ${this.state.canSubmitPlayerVote ? "" : styles.disabled}`} onClick={this.submitPlayerVote}>
                                        <Lottie options={VoteAnim}
                                            height="100%"
                                            width="100%"
                                            isClickToPauseDisabled={true}
                                            isPaused={this.state.voteAnimPaused}
                                            isStopped={this.state.voteAnimPaused}
                                            eventListeners={[
                                                {
                                                    eventName: 'DOMLoaded',
                                                    callback: () => {
                                                        console.log("DOMLoaded")
                                                    }
                                                },
                                                {
                                                    eventName: 'complete',
                                                    callback: () => {
                                                        console.log("vote complete")
                                                        this.pauseVote();
                                                        //this.state.canMoo ? this.doMoo(true) : console.log("paused moo")
                                                    }
                                                },
                                            ]}
                                        />
                                    </div>
                                    {/*<div className={`${styles.submitButton} ${styles.bottom} ${this.state.canSubmitPlayerVote ? "" : styles.disabled}`} onClick={this.submitPlayerVote}>VOTE</div>*/}
                                </div>
                            }
                            {
                                this.state.showWordGuessSection &&
                                <div className={styles.wordGuessSection}>
                                    <img className={styles.topChameleon} src={GreenChameleon} />
                                    <div className={styles.title}>{this.state.player.name}</div>
                                    <div className={styles.subTitle}>What was<br/>the secret word?:</div>
                                    <div className={styles.wordListSection}>
                                        <select className={styles.wordList} onChange={this.updateWordGuess}>
                                            <option value={"Select one..."}>Select One...</option>
                                            {
                                                this.state.currentTopic.Answers.map((x) => {
                                                    return <option value={x}>{x}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    
                                    <div className={`${styles.submitButton} ${this.state.canSubmitWordGuess ? "" : styles.disabled}`} onClick={this.submitWordGuess}>SUBMIT</div>
                                    
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
